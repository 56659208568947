import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { VideoBox, ImgBox, isImage, FeatureItem } from './shared'
import FramelessMobileView from './frameless-mobile-view'
import { H3 } from '@system'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    box: {
      maxWidth: '250px',
      maxHeight: '597px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '42px',
      marginTop: '20px',
    },
    media: {
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      border: '20px solid #E7E7E7',
      borderRadius: '50px',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
    },
    frame: {
      position: 'absolute',
      zIndex: 0,
    },
    image: {
      height: '560px',
      width: '270px',
      [theme.breakpoints.down('lg')]: {
        height: '500px',
        width: '230px',
      },
      '& >img': {
        objectFit: 'fill',
        borderRadius: 25,
        height: '100%',
        width: '100%',
      },
    },
    video: {
      height: '560px',
      width: '270px',
      [theme.breakpoints.down('lg')]: {
        height: '500px',
        width: '230px',
      },
      '& >video': {
        borderRadius: 25,
        objectFit: 'fill',
        height: '100%',
        width: '100%',
      },
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }
})

const MobileFrame = (props) => {
  const {
    handleSelect,
    features,
    filename,
    selected,
    title,
    deviceType,
  } = props
  const classes = useStyles()
  const featureCount = features.length
  features.forEach((f, i) => (f.id = i))
  const splitFeatures = (array, arraySize) =>
    Array(Math.ceil(array.length / arraySize))
      .fill()
      .map((_, index) => index * arraySize)
      .map((begin) => array.slice(begin, begin + arraySize))
  const featureArrays = splitFeatures(features, featureCount / 2)
  return (
    <>
      {title && <H3 className={classes.title}>{title}</H3>}
      <Box className={classes.root}>
        <Box className={classes.box} mx="auto" width="100%">
          {featureArrays[0].map((ft, index) => {
            return (
              <FeatureItem
                index={featureArrays[0][index].id}
                key={ft._uid}
                handleSelect={handleSelect}
              >
                {renderBlok(featureArrays[0][index], {
                  selected: selected === featureArrays[0][index].id,
                  deviceType,
                })}
              </FeatureItem>
            )
          })}
        </Box>
        <Box className={classes.media}>
          {isImage(filename) ? (
            <ImgBox classes={classes.image} src={filename} />
          ) : (
            <VideoBox classes={classes.video} src={filename} />
          )}
        </Box>
        <Box className={classes.box} mx="auto" width="100%">
          {featureArrays[1].map((ft, index) => {
            return (
              <FeatureItem
                index={featureArrays[1][index].id}
                key={ft._uid}
                handleSelect={handleSelect}
              >
                {renderBlok(featureArrays[1][index], {
                  selected: selected === featureArrays[1][index].id,
                  deviceType,
                })}
              </FeatureItem>
            )
          })}
        </Box>
      </Box>
      <FramelessMobileView {...props} />
    </>
  )
}

export default MobileFrame
