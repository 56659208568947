import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FeatureItem, ImgBox, VideoBox, isImage } from './shared'

const useStyles = makeStyles((theme) => {
  return {
    framelessMobileViewRoot: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    media: {
      width: '100%',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '37px',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      objectFit: 'contain',
      overflow: 'hidden',
      '& >img': {
        borderRadius: 18,
        height: '100%',
        width: '100%',
      },
    },
    video: {
      '& >video': {
        objectFit: 'contain',
        width: '100%',
      },
      width: '100%',
      overflow: 'hidden',
    },
    holder: {
      width: '290px',
      margin: '0 auto',
    },
    swiper: {
      '--swiper-pagination-color': theme.palette.common.black,
      '--swiper-pagination-bottom': '0',
      '--swiper-pagination-bullet-border-radius': '0',
      '--swiper-pagination-bullet-height': '6px',
      '--swiper-pagination-bullet-horizontal-gap': '6px',
      '--swiper-pagination-bullet-inactive-color':
        theme.palette.background.lightSlate,
      '--swiper-pagination-bullet-inactive-opacity': '1',
      '--swiper-pagination-bullet-width': '40px',
      '& .swiper-pagination': {
        position: 'relative',
      },
    },
  }
})

const FramelessMobileView = ({
  handleSelect,
  features,
  filename,
  selected,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.framelessMobileViewRoot}>
      <Box className={classes.media}>
        {isImage(filename) ? (
          <ImgBox classes={classes.image} src={filename} />
        ) : (
          <VideoBox classes={classes.video} src={filename} />
        )}
      </Box>
      <Swiper
        className={classes.swiper}
        index={selected}
        loop
        modules={[Pagination]}
        onSlideChange={(event) => {
          handleSelect(event.activeIndex)
        }}
        pagination={{
          clickable: true,
        }}
      >
        {features?.map((ft, index) => {
          return (
            <SwiperSlide key={ft._uid}>
              <FeatureItem
                handleSelect={handleSelect}
                className={classes.holder}
              >
                {renderBlok(features[index], {
                  selected: selected === index,
                })}
              </FeatureItem>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Box>
  )
}

export default FramelessMobileView
