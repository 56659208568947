import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { VideoBox, ImgBox, isImage, FeatureItem } from './shared'
import FramelessMobileView from './frameless-mobile-view'
import { H3 } from '@system'

const useStyles = makeStyles((theme) => {
  return {
    box: {
      maxWidth: '290px',
      maxHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    media: {
      maxHeight: '638px',
      width: '100%',
      maxWidth: '1056px',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      background: `url(${'https://a.storyblok.com/f/64835/x/5d524afb4a/desktop.svg'}) no-repeat`,
      backgroundSize: 'cover',
      paddingTop: '26px',
      overflow: 'hidden',
      paddingLeft: '1px',
      paddingRight: '1px',
      [theme.breakpoints.down('xl')]: {
        backgroundSize: 'contain',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '20px',
      },
    },
    image: {
      border: '1px solid #E4E4E4', // TODO: This is not a permanent solution - imgs and vids not confined to desktop frame svg in all cases
      width: '100%',
      objectFit: 'contain',
      overflow: 'hidden',
      '& >img': {
        height: '100%',
        width: '100%',
      },
    },
    video: {
      border: '1px solid #E4E4E4', // TODO: This is not a permanent solution - imgs and vids not confined to desktop frame svg in all cases
      '& >video': {
        objectFit: 'contain',
        width: '100%',
      },
      width: '100%',
      overflow: 'hidden',
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }
})

const DesktopFrame = (props) => {
  const classes = useStyles()
  const {
    handleSelect,
    features,
    filename,
    selected,
    title,
    deviceType,
  } = props
  return (
    <>
      {title && <H3 className={classes.title}>{title}</H3>}
      <Box className={classes.main}>
        <Box className={classes.box} mx="auto" width="100%">
          {features &&
            features.map((ft, index) => {
              return (
                <FeatureItem
                  index={index}
                  key={ft._uid}
                  handleSelect={handleSelect}
                >
                  {renderBlok(features[index], {
                    selected: selected === index,
                    deviceType,
                  })}
                </FeatureItem>
              )
            })}
        </Box>
        <Box className={classes.media}>
          {isImage(filename) ? (
            <ImgBox classes={classes.image} src={filename} />
          ) : (
            <VideoBox classes={classes.video} src={filename} />
          )}
        </Box>
      </Box>
      <FramelessMobileView {...props} />
    </>
  )
}

export default DesktopFrame
