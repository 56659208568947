import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { VideoBox, ImgBox, isImage, FeatureItem } from './shared'
import FramelessMobileView from './frameless-mobile-view'
import { H3 } from '@system'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      maxHeight: '556px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    box: {
      maxWidth: '245px',
      maxHeight: '556px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '33px',
      marginTop: '20px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '290px',
        justifyContent: 'flex-start',
      },
    },
    media: {
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      width: '100%',
      border: '20px solid #E7E7E7',
      borderRadius: '25px',
      [theme.breakpoints.down('md')]: {
        height: '425px',
      },
      [theme.breakpoints.down('sm')]: {
        background: 'unset',
      },
    },
    featureItem: {
      display: 'flex',
      maxWidth: '200px',
      paddingBottom: '33px',
      [theme.breakpoints.down('md')]: {},
    },
    frame: {
      position: 'absolute',
      zIndex: 0,
    },
    image: {
      width: '100%',
      '& >img': {
        borderRadius: 5,
        display: 'block',
        maxWidth: '688px',
        height: 'auto',
        width: 'auto',
        [theme.breakpoints.down('md')]: {
          maxWidth: '515px',
          borderRadius: 3,
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
        },
      },
    },
    video: {
      width: '100%',
      '& >video': {
        display: 'block',
        borderRadius: 5,
        maxWidth: '688px',
        height: 'auto',
        width: 'auto',
        [theme.breakpoints.down('md')]: {
          maxWidth: '515px',
          borderRadius: 3,
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
        },
      },
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }
})

const TabletFrame = (props) => {
  const classes = useStyles()
  const {
    handleSelect,
    features,
    filename,
    selected,
    title,
    deviceType,
  } = props
  return (
    <>
      {title && <H3 className={classes.title}>{title}</H3>}
      <Box className={classes.root}>
        <Box className={classes.box} mx="auto" width="100%">
          {features &&
            features.map((ft, index) => {
              return (
                <FeatureItem
                  index={index}
                  key={ft._uid}
                  handleSelect={handleSelect}
                  className={classes.featureItem}
                  type={deviceType}
                >
                  {renderBlok(features[index], {
                    selected: selected === index,
                    deviceType,
                  })}
                </FeatureItem>
              )
            })}
        </Box>
        <Box className={classes.media}>
          {isImage(filename) ? (
            <ImgBox classes={classes.image} src={filename} />
          ) : (
            <VideoBox classes={classes.video} src={filename} />
          )}
        </Box>
      </Box>
      <FramelessMobileView {...props} />
    </>
  )
}

export default TabletFrame
