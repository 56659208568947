import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import DesktopFrame from './desktop-frame'
import TabletFrame from './tablet-frame'
import MobileFrame from './mobile-frame'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const DeviceFrameFeatureModule = (props) => {
  const [value, setValue] = useState(0)
  const handleSelect = (value) => {
    setValue(value)
  }
  const filename = props.blok.features[value].previewMedia.filename

  const frame = () => {
    switch (props.blok.deviceType) {
      case 'mobile':
        return (
          <PageContainer py={2}>
            <ContentContainer>
              <MobileFrame
                handleSelect={handleSelect}
                filename={filename}
                features={props.blok.features}
                selected={value}
                deviceType={props.blok.deviceType}
                title={props.blok.title}
              />
            </ContentContainer>
          </PageContainer>
        )
      case 'tablet':
        return (
          <PageContainer py={2}>
            <ContentContainer>
              <TabletFrame
                handleSelect={handleSelect}
                filename={filename}
                features={props.blok.features}
                selected={value}
                deviceType={props.blok.deviceType}
                title={props.blok.title}
              />
            </ContentContainer>
          </PageContainer>
        )
      default:
        return (
          <PageContainer py={2}>
            <ContentContainer>
              <DesktopFrame
                handleSelect={handleSelect}
                filename={filename}
                features={props.blok.features}
                selected={value}
                deviceType={props.blok.deviceType}
                title={props.blok.title}
              />
            </ContentContainer>
          </PageContainer>
        )
    }
  }
  return <SbEditable content={props.blok}>{frame()}</SbEditable>
}

export default DeviceFrameFeatureModule
