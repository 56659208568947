import React from 'react'
import { Box } from '@material-ui/core'

export const FeatureItem = ({ children, ...props }) => {
  const { index, _uid, className } = props
  return (
    <Box
      onClick={() => {
        props.handleSelect(index)
      }}
      key={_uid}
      className={className}
    >
      {children}
    </Box>
  )
}

export const VideoBox = ({ classes, src }) => (
  <Box className={classes}>
    <video
      autoPlay={true}
      muted={true}
      loop={true}
      preload="true"
      src={src}
      type="video/mp4"
      webkit-playsinline="true"
      playsInline
    >
      Sorry your browser does not support HTML5 video
    </video>
  </Box>
)

export const ImgBox = ({ classes, src }) => (
  <Box className={classes}>
    <img src={src} alt="feature preview" />
  </Box>
)

export const isImage = (url) => url.match(/\.(jpeg|jpg|gif|png)$/) != null
